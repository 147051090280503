<template>
  <div class="sign">
    <div class="sign-head">
      <div class="wrapper">
        <div class="sign-head-container">
          <div class="head-left">
            <img src="../../assets/images/logo.png" alt="" style="height:34px;">
          </div>
          <div class="head-right" style="display: flex; align-items: center; color: #207AFF; font-size: 16px; cursor: pointer;" @click="$router.push({ path: '/' })">
            <img src="../../assets/images/home.png" alt="">
            <span style="margin-left: 4px;">返回首页</span>
          </div>
        </div>
      </div>
      
    </div>
    <div class="sign-container">
      <div class="box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import Header from '@/components/header/header.vue'
export default {
  setup() {}
}
</script>

<style lang="less" scoped>
  .sign-head {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px 0px rgba(196,196,196,0.5);
    .sign-head-container {
      height: 80px;
      display: flex;
      align-items: center;
      .head-left {
        flex: 1;
      }
      .head-right {
        display: flex;
        align-items: center;
      }
    }
  }
.sign {
  // position: fixed;
  // left: 0;
  // top: 0;
  // width: 100%;
  height: 100vh;
  overflow: hidden;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #F7F6FA;
}
.sign-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  .box {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 40px 0;
    // box-sizing: border-box;
  }
}
</style>
